import React, { useState, useEffect } from "react";
import { useMedia } from "use-media";
import Layout from "../../../components/chat-channel/Layout";
import AnchorLink from "react-anchor-link-smooth-scroll";
import Container from "../../../components/chat-channel/Container";
import TitleAndMetaTags from "../../../components/common/TitleAndHeader";
import { RequestForm } from "../../../components/form";
import { OnScrollPopup } from "../hr-chatbot";
import { GoToPopup } from "../../homepage";
import { CookiesPoup } from "../../homepage";
import {
  cta_1,
  cta_1_mob,
  cta_2,
  cta_2_mob,
  ExistingBlogCta,
} from "./4-reasons-to-use-ms-teams-as-a-digital-helpdesk";

import "react-alice-carousel/lib/alice-carousel.css";
import "react-accessible-accordion/dist/fancy-example.css";
import { redirectToWithUtm } from "../../../components/Header";

const topImage = require("../../../assets/images/market_ui/goto_testimonial_cta.png");

const image1 = require("../../../assets/images/blog_10/b10_1.webp");
const image2 = require("../../../assets/images/blog_10/b10_2.webp");
const image3 = require("../../../assets/images/blog_10/b10_3.jpg");
const image4 = require("../../../assets/images/blog_10/b10_4.jpg");
const image5 = require("../../../assets/images/blog_10/b10_5.jpg");
const image6 = require("../../../assets/images/blog_10/b10_6.jpg");
const image7 = require("../../../assets/images/blog_10/b10_7.jpg");
const image8 = require("../../../assets/images/blog_10/b10_9.webp");

export default function FeatureHomePage() {
  const isSmall = useMedia({ maxWidth: "520px" });

  return (
    <>
      <TitleAndMetaTags
        title="Enhance IT Support: Integrate SolarWinds Service Desk with Workativ Chatbot"
        description="Learn how SolarWinds Service Desk customers are integrating and building chatbot using Workativ Assistant and provide seamless self-service for employees."
        keywords={[
          "Slack Chatbot, Automation, AI Chatbot",
          "workativ free trial",
          "Slack chatbot free trial",
          "Slack Chatbot",
        ]}
        ogImage={image1}
      />
      <section className="market_main_wrapper chatbot-guide-blog-main-container">
        <Container>
          <Layout backgroundColor={"_bg_market"} logoFor="ASSISTANT">
            <div className="grid_container_chatbot">
              <div className="container">
                <div className="row">
                  <div className="grid_container_chatbot_wrapper">
                    <div className="grid__wrapper">
                      <div className="col-lg-12 col-md-12 col-xs-12 trial_header_left">
                        <div className="main-slider-left-market">
                          <h1 className="font-page-header ">
                            Integration of SolarWinds Service Desk with other
                            business apps using Workativ Assistant
                          </h1>
                          <img src={image1} className="mb-4"></img>
                        </div>
                      </div>
                      <div className=" market_wrapper_page">
                        <p class="font-section-normal-text line-height-2">
                          You might be familiar with SolarWinds Inc., the
                          American company that develops software for businesses
                          to help manage their networks, systems, and
                          information technology infrastructure. You might even
                          be using their software like SolarWinds Service Desk
                          for IT Service Management. What if there was a way for
                          you to improve the experience of using SolarWinds
                          Service Desk for your employees? There is, actually.
                          It’s called Workativ Assistant.
                        </p>
                        <p class="font-section-normal-text line-height-2">
                          Workativ Assistant is an AI-powered no-code platform
                          for building contextual chatbots with automated
                          workflows for internal IT/HR self-service. Employees
                          can make use of Workativ Assistant’s chatbots on your
                          business’{" "}
                          <a href="https://workativ.com/conversational-ai-platform/slack-chatbot-guide">
                            Slack
                          </a>{" "}
                          or{" "}
                          <a href="https://workativ.com/conversational-ai-platform/microsoft-teams-vs-slack">
                            Microsoft Teams.&nbsp;
                          </a>{" "}
                          workspace, either on their mobile phone on-the-go or
                          on their desktop. Sounds simple, right?
                        </p>
                        <p class="font-section-normal-text line-height-2">
                          When you augment your workplace with Workativ
                          Assistant’s chatbots, your employees get the IT/HR
                          support experience they’d love, and your help desk
                          agents’ morale increases.{" "}
                          <a href="https://www.reworked.co/digital-workplace/why-employee-experience-is-the-new-customer-experience/#:~:text=Happy%20and%20engaged%20employees%20create,ultimately%2C%20brand%20and%20company%20growth.&text=For%20starters%2C%20EX%20needs%20the,day%20employee's%20wants%20and%20needs">
                            Happy and engaged employees create better
                            experiences,
                          </a>{" "}
                          which leads to more satisfied and loyal customers and,
                          ultimately, brand and company growth. Talk about
                          killing two birds with one stone.
                        </p>
                        <p class="font-section-normal-text line-height-2">
                          Now that you’ve heard about Workativ Assistant, let’s
                          take a look at all the actions you can automate for
                          SolarWinds Service Desk with Workativ Assistant’s
                          chatbots.
                        </p>

                        <h2 class="font-section-sub-header-small line-height-2">
                          Smarter AI-powered SolarWinds Service Desk automations
                          you can set up with Workativ Assistant
                        </h2>

                        <p class="font-section-normal-text line-height-2">
                          Gone are the days employees have to deal with the
                          age-old self-service portals. Enter two-way,
                          interactive, IT/HR self-service chatbots. By
                          integrating your SolarWinds Service Desk with Workativ
                          Assistant, you can{" "}
                          <a href="https://workativ.com/conversational-ai-platform">
                            build chatbots
                          </a>{" "}
                          that enable seamless self-service for your employees.
                          Here are a list of SolarWinds Service Desk actions
                          your employees can take care of with Workativ
                          Assistant’s SolarWinds Service Desk chatbot.
                        </p>
                        <img loading="lazy" src={image2} className="mb-5"></img>

                        <p className="font-section-normal-text line-height-2">
                          <span className="font-section-normal-text-medium">
                            Creating an incident —
                          </span>
                          Your employees can easily create an IT incident from
                          the comfort of their Slack or Microsoft Teams app just
                          by having a quick chat with the Workativ Assistant
                          chatbot there.
                        </p>
                        <p className="font-section-normal-text line-height-2">
                          <span className="font-section-normal-text-medium">
                            Updating an incident —
                          </span>
                          Updating an incident can never be made easier for your
                          help desk agents than this. You help desk agents will
                          just have to bring up Workativ Assistant’s chatbot and
                          give it the required details to be updated for an
                          incident, and it updates the incident in a jiff.
                        </p>
                        <p className="font-section-normal-text line-height-2">
                          <span className="font-section-normal-text-medium">
                            Creating an incident —
                          </span>
                          Your employees can easily create an IT incident from
                          the comfort of their Slack or Microsoft Teams app just
                          by having a quick chat with the Workativ Assistant
                          chatbot there.
                        </p>
                        <p className="font-section-normal-text line-height-2">
                          <span className="font-section-normal-text-medium">
                            Retrieving details about an incident —
                          </span>
                          Whenever an employee feels like checking the status of
                          their incident, they can do so on-the-go with Workativ
                          Assistant’s chatbot. Tell Workativ Assistant’s chatbot
                          which incident you want to track and it brings up
                          details about that incident in the blink of an eye.
                        </p>
                        <p className="font-section-normal-text line-height-2">
                          <span className="font-section-normal-text-medium">
                            Deleting an incident —
                          </span>
                          In case an employee created an incident by mistake,
                          they can delete the incident with Workativ Assistant’s
                          chatbot, without having to log in to their SolarWinds
                          Service Desk workspace.
                        </p>
                        <p className="font-section-normal-text line-height-2">
                          <span className="font-section-normal-text-medium">
                            Creating a user —
                          </span>
                          New employee joining your team? You can add them to
                          your business’ SolarWinds Service Desk workspace just
                          by providing details about them to Workativ
                          Assistant’s chatbot. Also makes your help desk
                          agent/HR’s life easier by making approvals for
                          creating a user easier to give/track.
                        </p>
                        <p className="font-section-normal-text line-height-2">
                          <span className="font-section-normal-text-medium">
                            Updating user details —
                          </span>
                          Got an employee who has moved to a new place? With
                          Workativ Assistant’s chatbot, you can easily enable
                          your employees to change details about them in
                          SolarWinds Service Desk just by verifying that it’s
                          them via Twilio OTP verification.
                        </p>
                        <p className="font-section-normal-text line-height-2">
                          <span className="font-section-normal-text-medium">
                            Getting details about a user —
                          </span>
                          Your help desk agents can get details about an
                          employee to solve their IT incident just by asking
                          Workativ Assistant’s chatbot for it. No extra effort
                          required.
                        </p>
                        <p className="font-section-normal-text line-height-2">
                          <span className="font-section-normal-text-medium">
                            Removing a user —
                          </span>
                          If there’s an outgoing employee, your HR/help desk
                          agent can remove them from your business’ SolarWinds
                          Service Desk workspace by having a conversation with
                          Workativ Assistant’s chatbot.
                        </p>
                        <p class="font-section-normal-text line-height-2">
                          And this is just the tip of the iceberg.
                        </p>
                      </div>

                      <div>
                        <h5 class="font-section-sub-header-small line-height-2">
                          AI-Powered Chatbots Integration with existing IAM, HRM
                          and Collaboration Systems
                        </h5>
                        <h5 class="font-section-sub-header-small">
                          1. Microsoft 365{" "}
                        </h5>
                        <p class="font-section-normal-text line-height-2">
                          Be it creating a group or adding a user to a group,
                          you can do this and more on Microsoft Office 365 with
                          Workativ Assistant’s chatbot.
                        </p>
                        <p class="font-section-normal-text line-height-2">
                          Let’s say you want to create a Microsoft 365 group and
                          add your teammates to it. For this, you can:
                        </p>
                        <ol
                          class="font-section-normal-text float-left"
                          style="width:100%"
                        >
                          <li>
                            Create a chatbot with automated workflows for
                            Microsoft 365 using Workativ Assistant’s Automation
                            Designer{" "}
                          </li>
                          <li>
                            Create conversation/dialog variations for the
                            <a href="https://workativ.com/conversational-ai-platform/office-365-chatbot">
                              &nbsp;Microsoft 365 chatbot&nbsp;
                            </a>{" "}
                            in natural language{" "}
                          </li>
                          <li>
                            Add the chatbot to your team’s Slack/Microsoft Teams
                            channel{" "}
                          </li>
                        </ol>

                        <p class="font-section-normal-text line-height-2">
                          You can even set up the automation in such a way that
                          when things go haywire, the chatbot will create a
                          SolarWinds Service Desk incident automatically in open
                          status and send a heads up email to your help desk
                          agents.
                        </p>
                        <img loading="lazy" src={image3} className="mb-5"></img>
                        <h5 class="font-section-sub-header-small">
                          2. OrangeHRM
                        </h5>
                        <p class="font-section-normal-text line-height-2">
                          Your HR team might become overloaded if they have to
                          deal with employee queries/requests for simple things
                          like updating an employee’s details on an HRMS like
                          <a href="https://workativ.com/conversational-ai-platform/orange-hrm-chatbot">
                            {" "}
                            OrangeHRM.
                          </a>{" "}
                          No more. With Workativ Assistant’s chatbot, you can
                          automate this in such a way that, after{" "}
                          <a href="https://workativ.com/conversational-ai-platform/twilio-chatbot">
                            OTP verification,
                          </a>{" "}
                          an employee will just have to provide details about
                          the information they want to change, and the chatbot
                          gets it done in a flash. And as always, you can set up
                          a fail-safe automation for creating a SolarWinds
                          Service Desk incident as open in case things go
                          somewhat awry with the OrangeHRM automation. No extra
                          effort required.
                        </p>
                        <img loading="lazy" src={image4} className="mb-5"></img>

                        <h5 class="font-section-sub-header-small">
                          3. Dropbox
                        </h5>
                        <p class="font-section-normal-text line-height-2">
                          Whether it’d be Dropbox or Dropbox for business, you
                          can effortlessly set up a Workativ Assistant chatbot
                          of retrieving a file’s link from Dropbox for your
                          employees. And as usual, in case the Dropbox
                          automation fails, you can set up the chatbot such that
                          it will create a SolarWinds Service Desk incident and
                          alert your help desk team about it.
                        </p>
                        <img loading="lazy" src={image5} className="mb-5"></img>

                        <h5 class="font-section-sub-header-small">
                          How you can easily create a SolarWinds Service Desk
                          chatbot using Workativ Assistant
                        </h5>
                        <p class="font-section-normal-text line-height-2">
                          When it comes to setting up an automation and
                          <a href="http://web.workativ.ai/conversational-ai-platform/blog/service-desk-chatbot-guide">
                            conversation/dialog for a SolarWinds Service Desk
                            chatbot
                          </a>{" "}
                          using Workativ Assistant, it’s just a click there and
                          a text there. Incredible? Read on to see for
                          yourself..
                        </p>

                        <h5 class="font-section-sub-header-small">
                          1. Workflow builder
                        </h5>
                        <p class="font-section-normal-text line-height-2">
                          Workativ assistant comes with easy-to-use no-code
                          workflow builder to help you build simple to complex
                          app-based automations for chatbot in minutes.
                          Integrate chatbot with apps, use pre-built workflows
                          from marketplace, approve requests, connect chatbot
                          with on-prem apps and much more.
                        </p>

                        <img loading="lazy" src={image6} className="mb-5"></img>
                        <h5 class="font-section-sub-header-small">
                          2. Chatbot Builder
                        </h5>
                        <p class="font-section-normal-text line-height-2">
                          Our easy-to-use no-code chatbot builder helps you
                          build and manage simple to complex conversations,
                          FAQs, integrate app workflows, and personalize your
                          bot in minutes. Deliver autonomous workplace support
                          with purpose built intelligent chatbots.
                        </p>
                        <img loading="lazy" src={image7} className="mb-3"></img>
                        <img loading="lazy" src={image8} className="mb-5"></img>
                      </div>

                      <NocodeWrapper />
                      <h6 className="font-section-sub-header-small-bold">
                        Benefits of integrating SolarWinds Service Desk with
                        Workativ Assistan
                      </h6>
                      <p class="font-section-normal-text line-height-2">
                        Now there are countless benefits to using Workativ
                        Assistant alongside SolarWinds Service Desk.
                      </p>
                      <div className="market_wrapper_page ">
                        <h6 className="font-section-sub-header-small ">
                          1. Intuitive conversational AI for an awesome employee
                          experience
                        </h6>
                        <p class="font-section-normal-text line-height-2">
                          No one prefers navigating through boring self-service
                          portals even if it’s SolarWinds Service Desk. With
                          Workativ Assistant’s chatbots, provide the seamless
                          <a href="https://workativ.com/knowledge/self-service-automation">
                            &nbsp;conversational self-service
                          </a>{" "}
                          that your employees would appreciate.
                        </p>

                        <h6 className="font-section-sub-header-small ">
                          2. All from the comfort of your chat hub
                        </h6>
                        <p class="font-section-normal-text line-height-2">
                          Workativ Assistant’s SolarWinds Service Desk chatbot
                          proactively resides on your Slack/Microsoft Teams
                          workspace waiting to resolve your employees’ IT/HR
                          queries in an instant, 24×7.
                        </p>

                        <h6 className="font-section-sub-header-small ">
                          3. An exponential rise in employee productivity
                        </h6>
                        <p class="font-section-normal-text line-height-2">
                          With Workativ Assistant’s SolarWinds Service Desk
                          chatbot taking care of repetitive tasks like creating
                          an incident, your help desk agents can focus on tasks
                          that need a human touch and your employees get the
                          satisfaction of solving their issue themselves,
                          leading a greater increase in overall productivity.
                        </p>

                        <h6 className="font-section-sub-header-small ">
                          4. Better ROI on your workplace IT/HR support
                        </h6>
                        <p class="font-section-normal-text line-height-2">
                          By{" "}
                          <a href="https://workativ.com/conversational-ai-platform/ai-self-service-chatbot">
                            augmenting your employee IT/HR support
                          </a>{" "}
                          with Workativ Assistant alongside SolarWinds Service
                          Desk, you ensure that you get the best ROI on your
                          workplace support from the above-mentioned benefits.
                        </p>

                        <p class="font-section-normal-text line-height-2">
                          So, what’s stopping you from trying out Workativ
                          Assistant? Sign up for FREE trial today at
                          workativ.com and get started right away!
                        </p>
                      </div>
                      <ExistingBlogCta
                        ContentCta="Auto-resolve 60% of Your Employee Queries With Generative AI Chatbot & Automation."
                        ButtonText="Book a Demo"
                        isColor="black"
                        backgroundImage={cta_1}
                        mobileBackgroundImage={cta_1_mob}
                      />
                      <div className="most_popular_links">
                        <div className="most_popular_header font-section-sub-header-bold">
                          Related Articles
                        </div>
                        <div className="most_popular_ul">
                          <ul
                            className="section__ul"
                            style={{ width: "100%", float: "left" }}
                          >
                            <li>
                              <a href="https://workativ.com/conversational-ai-platform/conversational-ai-chatbot-for-employee-service">
                                Conversational AI Chatbot for employee service
                                automation
                              </a>
                            </li>
                            <li>
                              <a href="https://workativ.com/conversational-ai-platform/top-tools-to-build-conversational-ai-chatbot">
                                {" "}
                                Conversational AI Tools—Top 6 Tools To Build
                                Conversational AI Chatbot
                              </a>
                            </li>
                            <li>
                              <a href="https://workativ.com/conversational-ai-platform/chatbot-best-practices-for-designing-a-conversational-ai">
                                Chatbot Best Practices for designing a
                                Conversational Experience with Workativ
                                Assistant
                              </a>
                            </li>
                          </ul>
                        </div>
                      </div>

                      <div className="button_last">
                        <button>
                          <a href="https://blog.workativ.com/category/conversational-ai">
                            Conversational AI
                          </a>
                        </button>
                      </div>
                    </div>
                    <div className="sticky_right_img">
                      <a href="/conversational-ai-platform/case-studies/goto-implements-chatbot-for-it-helpdesk-automation">
                        {" "}
                        <img src={topImage} alt="goto testimonial cta" />{" "}
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {/* {isSmall ? null : <OnScrollPopup />} */}

            {/* <GoToPopup />
            <CookiesPoup /> */}
            <RequestForm isFooterForm={true} />
          </Layout>
        </Container>
      </section>
    </>
  );
}

const NocodeWrapper = () => {
  return (
    <div className="nocode_wrapper mt-0 ">
      <h4>No Code - Free SolarWinds Chatbot </h4>
      <button>
        <a
          href="https://assistant.workativ.com/authentication/u/direct-signup"
          alt="solarwinds chatbot"
        >
          Get Started
        </a>
      </button>
    </div>
  );
};
